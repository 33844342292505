import * as React from "react";
import "../../styles/navbar.scss";
import { Box, Button, Modal, Typography } from "@mui/material";
import AwardWriteup from "../Modal/AwardWriteup";
import AwardFile from "../../images/ctm360_frost_&_Sullivan_award.pdf";
import ReportFile from "../../images/analysis_of_2023_global_cti_report.pdf";
import { Snackbar, Alert } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import "swiper/css";
import saveAs from "file-saver";
import { Link } from "gatsby";

export default function Recognition() {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [modalData, setModalData] = React.useState({ title: "", url: "" });
  const handleOpen = (title: string, url: string) => {
    const data = { title: title, url: url };
    setOpen(true);
    setModalData(data);
  };

  const [showSuccess, setShowSuccess] = React.useState(false);
  const handleCloseSuccess = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSuccess(false);
  };

  const handleClose = () => setOpen(false);
  const handleSuccess = () => {
    handleClose();
    setShowSuccess(true);
  };

  const handleDownload = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (!executeRecaptcha) return;

    setLoading(true);
    const token = await executeRecaptcha("reportDownload");
    const jsonobj = {
      title: "Analysis of 2023 Global CTI Report Download",
      fromEmail: "info@ctm360.com",
      captcha_token: token,
    };

    fetch("https://ctm-website-form.ctm360.com/api/form/report", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        dataType: "json",
      },
      body: JSON.stringify(jsonobj),
    })
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        alert(error);
      })
      .finally(() => {
        saveAs(ReportFile, "Analysis_of_2023_Global_CTI_Reports");
      });
  };
  return (
    <Box className="recognition-banner">
      <Swiper
        className="mySwiper"
        loop
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        modules={[Autoplay]}
      >
        <SwiperSlide>
          <Typography variant="subtitle1">
            2023-24 ThreatScape Report: Analysis of 2023 Global CTI Reports
          </Typography>
          {/* <a href={ReportFile} download="Analysis_of_2023_Global_CTI_Reports"> */}
          <Button
            className="btn"
            // onClick={(e) => handleDownload(e)}
            disabled={loading}
          >
            <Link
              to={"/threatscape-report"}
              style={{ textDecoration: "inherit" }}
            >
              {loading ? "Loading..." : "Download Report Now"}
            </Link>
          </Button>
          {/* </a> */}
        </SwiperSlide>
        <SwiperSlide>
          <Typography variant="subtitle1">
            Recognized by Frost & Sullivan for Enabling Technology Leadership in
            the Global Digital Risk Protection (DRP) Industry
          </Typography>
          <Button
            className="btn"
            onClick={() => handleOpen("Frost & Sullivan Award", AwardFile)}
          >
            Download Award Writeup
          </Button>
        </SwiperSlide>
      </Swiper>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        keepMounted
      >
        <AwardWriteup
          data={modalData}
          handleClose={handleClose}
          handleSuccess={handleSuccess}
        />
      </Modal>
      <Snackbar
        open={showSuccess}
        autoHideDuration={3000}
        onClose={handleCloseSuccess}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          sx={{ width: "100%" }}
        >
          File Downloaded!
        </Alert>
      </Snackbar>
    </Box>
  );
}
