exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blogs-index-tsx": () => import("./../../../src/pages/blogs/index.tsx" /* webpackChunkName: "component---src-pages-blogs-index-tsx" */),
  "component---src-pages-blogs-template-tsx": () => import("./../../../src/pages/blogs/template.tsx" /* webpackChunkName: "component---src-pages-blogs-template-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-data-sheets-tsx": () => import("./../../../src/pages/data-sheets.tsx" /* webpackChunkName: "component---src-pages-data-sheets-tsx" */),
  "component---src-pages-drp-digital-risk-protection-technology-stack-tsx": () => import("./../../../src/pages/drp-digital-risk-protection-technology-stack.tsx" /* webpackChunkName: "component---src-pages-drp-digital-risk-protection-technology-stack-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-free-access-tsx": () => import("./../../../src/pages/free-access.tsx" /* webpackChunkName: "component---src-pages-free-access-tsx" */),
  "component---src-pages-glossary-tsx": () => import("./../../../src/pages/glossary.tsx" /* webpackChunkName: "component---src-pages-glossary-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-media-tsx": () => import("./../../../src/pages/media.tsx" /* webpackChunkName: "component---src-pages-media-tsx" */),
  "component---src-pages-our-strategy-tsx": () => import("./../../../src/pages/our-strategy.tsx" /* webpackChunkName: "component---src-pages-our-strategy-tsx" */),
  "component---src-pages-partnerships-tsx": () => import("./../../../src/pages/partnerships.tsx" /* webpackChunkName: "component---src-pages-partnerships-tsx" */),
  "component---src-pages-platform-email-intelligence-oversight-tsx": () => import("./../../../src/pages/platform/email-intelligence-oversight.tsx" /* webpackChunkName: "component---src-pages-platform-email-intelligence-oversight-tsx" */),
  "component---src-pages-platform-external-attack-surface-management-tsx": () => import("./../../../src/pages/platform/external-attack-surface-management.tsx" /* webpackChunkName: "component---src-pages-platform-external-attack-surface-management-tsx" */),
  "component---src-pages-platform-global-cyber-threat-intelligence-tsx": () => import("./../../../src/pages/platform/global-cyber-threat-intelligence.tsx" /* webpackChunkName: "component---src-pages-platform-global-cyber-threat-intelligence-tsx" */),
  "component---src-pages-platform-targeted-threat-intelligence-tsx": () => import("./../../../src/pages/platform/targeted-threat-intelligence.tsx" /* webpackChunkName: "component---src-pages-platform-targeted-threat-intelligence-tsx" */),
  "component---src-pages-platform-vendorcontrols-tsx": () => import("./../../../src/pages/platform/vendorcontrols.tsx" /* webpackChunkName: "component---src-pages-platform-vendorcontrols-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-solutions-template-tsx": () => import("./../../../src/pages/solutions/template.tsx" /* webpackChunkName: "component---src-pages-solutions-template-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-threatscape-report-tsx": () => import("./../../../src/pages/threatscape-report.tsx" /* webpackChunkName: "component---src-pages-threatscape-report-tsx" */),
  "component---src-pages-webinars-template-tsx": () => import("./../../../src/pages/webinars/template.tsx" /* webpackChunkName: "component---src-pages-webinars-template-tsx" */)
}

